<template>
  <ul id="element-settings" class="uk-switcher uk-margin">
    <li class="uk-active">
      <div class="uk-width-1-1">
        <div class="uk-background-default uk-padding-small">
          <div class="uk-form-stacked">
            <div class="uk-margin">
              <!--<editor :name="'content-' + form.id" v-model="form.content"></editor>-->
              <editor :init="initEditor" v-model="form.content"></editor>
            </div>
            <div class="uk-margin">
              <label class="uk-form-label">Element ID</label>
              <input type="text" class="uk-input" v-model="form.html_id">
            </div>
            <div class="uk-margin">
              <label>
                <input type="checkbox" class="uk-checkbox" v-model="form.enable_scroll" /> Scrollen zum Anker aktivieren
              </label>
              <div class="uk-margin-small-top uk-width-medium" v-if="form.enable_scroll">
                <label class="uk-form-label">Scroll offset</label>
                <div class="uk-grid-small uk-flex-middle uk-grid" uk-grid>
                  <div class="uk-width-expand">
                    <input class="uk-range" type="range" value="0" min="0" max="400" step="10" v-model="form.scroll_offset">
                  </div>
                  <div class="uk-width-auto">
                    <input type="text" class="uk-input uk-form-width-xsmall" v-model="form.scroll_offset">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="uk-width-1-1 uk-margin">
        <div>
          <general-settings :form="form" />
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import GeneralSettings from './../GeneralSettings'
// import Editor from "@/components/Editor";
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/themes/silver/theme'
import 'tinymce/plugins/link'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/code'
import Editor from '@tinymce/tinymce-vue'
export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  components: {
    Editor,
    GeneralSettings
  },
  data () {
    return {
      form: {
        content: ''
      },
      initEditor: {
        menubar: false,
        branding: false,
        relative_urls: false,
        document_base_url: '/',
        min_height: 425,
        max_height: 800,
        toolbar: 'bold italic | styleselect | alignleft aligncenter alignright alignjustify | bullist numlist | link neqstImage | code',
        plugins: 'code link autoresize lists advlist paste',
        autoresize_bottom_margin: 50,
        language_url: '/tinymce/langs/de.js',
        language: 'de',
        // paste_as_text: true,
        skin_url: '/tinymce/skins/ui/oxide',
        content_css: '/tinymce/skins/content/default/content.min.css',
        external_plugins: {
          neqstImage: '/tinymce/plugins/image-picker/plugin.js'
        }
      }
    }
  },
  mounted () {
    this.form = this.data
  }
}
</script>
